:root{
    --darkblue-color: #0E1424;
    --cyan-color: #ABD9D9;
    --white-color: #FFFFFF;
    --gray-color: #4C4F58;
    --lightcyan-color: #ABDBC6;
    --yellow-color: #fbd848;
    --black-color: #000000;
    --width: 450px;
    --height: calc( 40vh - 50px );
    /* background: linear-gradient(135deg,var(--darkblue-color),70%,var(--cyan-color) ); */
    font-family: "Poppins",sans-serif;
}



*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    margin: 0;
    font-family: "Poppins",sans-serif;
    font-size: 16px;
    overflow-x: hidden;
}

.master_wrapper{
    margin: auto;

    overflow-x: hidden;
    overflow-y: hidden;
}