/* main section */
.exploreOurProducts{
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  margin-top: 200px;
}


.background_for_herosection{
  position: relative;
  width: 100%;
  height: 1000px;
  margin-top: 150px;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
}


.background_for_herosection p{
  position: relative;
  height: 620px;
  display: flex;
  justify-content: start;
  top: 0px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-size: 250px;
  line-height: 250px;

  -webkit-text-stroke: 10px white;
  color: transparent;
  -webkit-background-clip: text; /* Safari/Chrome */
  -webkit-text-fill-color: transparent; /* Safari/Chrome */
  background-clip: text; /* Standard syntax */
  background-image: url(../Images/animate.png);
  animation: animateHeroSectionName 20s linear infinite;
  background-position-y: -40px;
  pointer-events: none;

  z-index: 2;
  
  margin-left: -600px;
  user-select: none;
}

@keyframes animateHeroSectionName{
  100%{
    background-position: 2000px -40px;
  }
}



.fire-video{
  height: 1000px;
  margin-top: -585px;
  opacity: 0.8;
}

.fire-video-container{
  z-index: 0;
  user-select: none;
}

.buttons{
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-right: -900px;
  margin-top: -500px;
  z-index: 5;
  cursor: pointer;
}

.for-herosection{
  margin-top: 250px;
  padding: 30px 75px 30px 75px;
  font-size: 25px;
  font-family:'Poppins',sans-serif;
  font-weight: 800;
  border-radius: 60px;
  background:transparent;
  color: white;
  border: 3px solid white;
  cursor:pointer;
  position: relative;
  z-index: -3;
}

.b2{
  background: white;
  color: black;
  margin-top: 10px;
}

.b1:hover, .b2:hover{
  opacity:0.7;
}

.b2:active{
  background: transparent;
  color: white;
}

.b1:active{
  background: white;
  color: black;
}

@media screen and (max-width: 1650px) {
  .background_for_herosection{
    height: 760px;
  }

  .background_for_herosection p{
    font-size: 200px;
    line-height: 190px;
    margin-left: -500px;

    -webkit-text-stroke: 8px white;
  }
  
  .fire-video{
    height: 760px;
    margin-top: -485px;
  }

  .buttons{
    margin-top: -600px;
  }
}

@media screen and (max-width: 1650px) and (max-height: 1200px) {
  .background_for_herosection{
    height: 561px;
  }

  .background_for_herosection p{
    font-size: 150px;
    line-height: 140px;
    margin-left: -300px;

    -webkit-text-stroke: 6px white;
  }
  
  .fire-video{
    height: 560px;
    margin-top: -384px;
  }

  .buttons{
    margin-top: -600px;
    margin-right: -600px;
  }

  .for-herosection{
    padding: 20px 45px 20px 45px;
  }
}

@media screen and (max-width: 1650px) and (max-height: 700px) {
  .background_for_herosection{
    height:490px;
  }

  .background_for_herosection p{
    font-size: 130px;
    line-height: 120px;
    margin-left: -300px;
    top: 5px;
    -webkit-text-stroke: 6px white;
  }
  
  .fire-video{
    height: 490px;
    margin-top: -318px;
  }

  .buttons{
    margin-top: -550px;
    margin-right: -600px;
  }

  .for-herosection{
    font-size: 15;
    padding: 10px 35px 10px 35px;
  }
}

@media screen and (max-width: 1650px) and (max-height: 600px) {
  .background_for_herosection{
    height:390px;
  }

  .background_for_herosection p{
    font-size: 100px;
    line-height: 90px;
    margin-left: -200px;
    top: 10px;
    -webkit-text-stroke: 4px white;
  }
  
  .fire-video{
    height: 390px;
    margin-top: -245px;
  }

  .buttons{
    margin-top: -500px;
    margin-right: -400px;
  }

  .for-herosection{
    font-size: 15;
    padding: 10px 35px 10px 35px;
  }
}


@media screen and (max-width: 950px) and (max-height: 1400px) {
  .background_for_herosection{
    height:490px;
  }

  .background_for_herosection p{
    font-size: 90px;
    line-height: 100px;
    margin-left: -200px;
    top: 40px;
    -webkit-text-stroke: 3px white;
  }
  
  .fire-video{
    height: 490px;
    margin-top: -295px;
  }

  .buttons{
    margin-top: -490px;
    margin-right: -400px;
  }

  .for-herosection{
    font-size: 15;
    padding: 10px 35px 10px 35px;
  }
}



@media screen and (max-width: 620px) and (max-height: 1000px) {
  .background_for_herosection{
    height:490px;
  }

  .background_for_herosection p{
    font-size: 70px;
    line-height: 70px;
    margin-left: -200px;
    top: 90px;
    -webkit-text-stroke: 3px white;
  }
  
  .fire-video{
    height: 490px;
    margin-top: -295px;
  }

  .buttons{
    margin-top: -490px;
    margin-right: -290px;
  }

  .for-herosection{
    font-size: 12;
    padding: 7px 20px 7px 20px;
  }
}

@media screen and (max-width: 500px) and (max-height: 1000px) {
  .background_for_herosection{
    height:490px;
  }

  .background_for_herosection p{
    font-size: 80px;
    line-height: 80px;
    margin-left: 0px;
    top: 50px;
    -webkit-text-stroke: 3px white;
    margin-bottom: -20px;


    text-align: center;
  }
  
  .fire-video{
    height: 490px;
    margin-top: -455px;
  }

  .buttons{
    display: flex;
    flex-direction: row;
    margin-top: -150px;
    margin-left: 40px;
    margin-right: 40px;
    gap: 10px;
  }

  .for-herosection{
    font-size: 12;
    padding: 7px 20px 7px 20px;
  }
}


@media screen and (max-width: 385px) and (max-height: 1000px) {
  .background_for_herosection{
    height:490px;
  }

  .background_for_herosection p{
    font-size: 80px;
    line-height: 80px;
    margin-left: 0px;
    top: 50px;
    -webkit-text-stroke: 3px white;
    margin-bottom: -20px;


    text-align: center;
  }
  
  .fire-video{
    height: 490px;
    margin-top: -455px;
  }

  .buttons{
    display: flex;
    flex-direction: row;
    margin-top: -150px;
    margin-left: 40px;
    margin-right: 40px;
    gap: 10px;
  }

  .for-herosection{
    font-size: 7;
    padding: 7px 10px 7px 10px;
  }
}



@media screen and (max-width: 340px) and (max-height: 1000px) {
  .background_for_herosection{
    height:490px;
  }

  .background_for_herosection p{
    font-size: 70px;
    line-height: 80px;
    margin-left: 0px;
    top: 50px;
    -webkit-text-stroke: 3px white;
    margin-bottom: -40px;


    text-align: center;
  }
  
  .fire-video{
    height: 490px;
    margin-top: -550px;
  }

  .buttons{
    display: flex;
    flex-direction: row;
    margin-top: -150px;
    margin-left: 40px;
    margin-right: 40px;
    gap: 10px;
  }

  .for-herosection{
    font-size: 7;
    padding: 7px 10px 7px 10px;
  }
}

@media screen and (max-width: 300px) and (max-height: 1000px) {
  .background_for_herosection{
    height:490px;
  }

  .background_for_herosection p{
    font-size: 60px;
    line-height: 80px;
    margin-left: -25px;
    top: 50px;
    -webkit-text-stroke: 3px white;
    margin-bottom: -40px;


    text-align: center;
  }
  
  .fire-video{
    height: 490px;
    margin-top: -550px;
  }

  .buttons{
    display: flex;
    flex-direction: row;
    margin-top: -150px;
    margin-left: 40px;
    margin-right: 40px;
    gap: 10px;
  }

  .for-herosection{
    font-size: 7;
    padding: 7px 10px 7px 10px;
  }
}




