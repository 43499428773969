.mobile_nav {
  display: none;
}

.desktop_navigation{
  position: fixed;
  width: 100%;
  height: 112px;
  background: var(--black-color);
  z-index: 4;
}
.companyLogoAnchor{
  position: absolute;
  left: 3.06%;
  top: 30px;
  font-size: 32px;
  font-weight: 800;
  color: var(--white-color);
  text-decoration: none;
}

.desktopNavUnorderedHorizontalList{
  position: relative;
  display: flex;
  justify-content: right;
  margin-right: 4%;
  top:40px;
  gap: 3em;
  list-style: none;
}

.navHorizontalListChild{
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  color: var(--white-color);
}

.navHorizontalListContainer:hover .navHorizontalListChild{
  color: var(--yellow-color);
}


.navHorizontalListChild.active{
  color: var(--yellow-color);
}

.dropdownContainer{
  position: absolute;
  background: black;
  border: 2px solid white;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 290px;
  border-radius: 20px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3 s;
}

.dropdownContainerChild{
  position: absolute;
  background: black;
  border: 2px solid white;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 290px;
  border-radius: 20px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3 s;
  transform: translateX(-290px) translateY(-100px);
}

.navHorizontalListContainer:hover .dropdownContainer{
  opacity: 1;
  pointer-events: auto;
}

.dropdownParentForChildContainer:hover .dropdownContainerChild{
  opacity: 1;
  pointer-events: auto;
}
.dropdownParentForChild.active{
  background: gray;
  border: 2px solid white;
  padding: 10px 60px 10px 10px;
  border-radius: 10px;
  color: yellow;
}
.dropdownContainer.active{
  opacity: 1;
  pointer-events:auto;
}
.dropdownContainerChild.active{
  opacity: 1;
  pointer-events: auto;
}

.dropdownContents{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-left: 50px;
}
.dropdownContentChild{
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  color: var(--white-color);
}
.dropdownContentChild:hover{
  background: gray;
  border: 2px solid white;
  padding: 10px 60px 10px 10px;
  border-radius: 10px;
  color: yellow;
}

.navLogin.hidden{
  display: none;
}
@media screen and (max-width:1300px){
  .desktopNavigationNavBar{
      display: none;
  }

  .mobile_nav{
      display:flex;
  }
  
  .menu_icon{
      position: fixed;
      left: 86%;
      top: 333px;
      zoom: 10%;
      z-index: 6;
  }

  .mobile_menu_container{
      position: fixed;
      display: flex;
      height: 100vh;
      overflow-y: scroll;
      width: 100%;
      top: 0 ;
      left: 100%;
      background: black ;
      align-items: start;
      justify-content: center;
      z-index: 205;
      transition: all 400ms ease;
      user-select: none;
  }
  .mobile_menu_container.active{
      left: 0%;
  }
  .mobile_menu_container .close_icon {
      top: 333px;
      position: fixed;
      display: flex;
      zoom: 10%;
      left: 86%;
      opacity: 0;
      pointer-events: none;
      transition: all 400ms ease;
  }
  .mobile_menu_container .close_icon.active{
      opacity: 1;
      pointer-events: auto;
  }
  .mobileMenuVerticalUnorderedList{
      margin-top: 80px;
      list-style: none;
      text-decoration: none;
  }
  .mobileMenuChild{
      font-size: 30px;
      font-weight: 400;
      font-style: normal;
      text-decoration: none;
      color: var(--white-color);
  }
  .greaterThan{
      display: inline-block;
      transform: translateX(10px);
      transition: transform 0.15s ease;
  }
  .greaterThan.active{
      transform: translateX(10px) rotate(-180deg) ;
  }
  .mobileMenuChild:hover{
      color:yellow;
  }
  .mobileDropDownContentChild{
      color: white;
      font-size: 20px;
      text-decoration: none;
      margin-left: 30px;
  }
  
  .mobileDropDownContentChild:hover{
      color: yellow;
  }
  .mobileDropDownContainer{
      pointer-events: none;
      display: none;
      transition: display 0.6s ease;
  }
  .mobileDropDownContainer.active{
      display: block;
      opacity: 1;
      pointer-events: auto;
  }
  .mobileDropDownChildContent{
      transform: translateX(40px);
  }
  .mobileDropDownContainerChild{
      pointer-events: none;
      display: none;
      transition: display 0.6s ease;
  }
  .mobileDropDownContainerChild.active{
      display: block;
      opacity: 1;
      pointer-events: auto;
  }
}

@media screen and (max-width:650px){
  .desktop_navigation{
      height: 100px;
  }

  .companyLogoAnchor{
      font-size: 25px;
  }

  .menu_icon{
      top: 444px;
      zoom: 8%;
  }
}

@media screen and (max-width:500px){
  .desktop_navigation{
      height: 90px;
  }

  .companyLogoAnchor{
      font-size: 20px;
  }

  .menu_icon{
      top: 555px;
      zoom: 6%;
  }
}

@media screen and (max-width:400px){
  .desktop_navigation{
      height: 70px;
  }

  .companyLogoAnchor{
      top: 25px;
      font-size: 15px;
  }

  .menu_icon{
      top: 720px;
      zoom: 4%;
  }
}

@media screen and (max-width:300px){
  .desktop_navigation{
      height: 40px;
  }

  .companyLogoAnchor{
      top: 12px;
      font-size: 10px;
  }

  .menu_icon{
      top: 460px;
      zoom: 3%;
  }
}

