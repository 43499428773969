.productMaster{
    display: flex;
    margin-top:200px;
    margin-bottom:200px;
    margin-left:100px;
    margin-right:100px;
}

.productPageNavigation{
    margin-top: 120px;
    color: black;
    font-size: 20px;
}

.navProductPageContent{
    cursor: pointer;    
}

.navProductPageContentChild{
    color: rgb(130, 128, 128);
    text-decoration: none;
    font-weight: bold;
}

.navProductPageContentChild:hover{
    color: black;  
}

.displayImagesGrid{
    margin:auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 50px;
    width: 100%;
}


.imgContainer{
    /* border: 1px solid black; */
    box-shadow: 5px 10px 18px black ;
    border-radius: 10px;
    opacity: 1;
    transition: opacity 0.15s;
    display: flex;
    flex-direction:column;
    align-items: center;
    
}


.displayImages{
    width: 90%;
    height: 200px;
    object-fit: contain;
    margin-top: 20px;
    cursor: pointer;
    vertical-align: top;
    margin-left: 5%;
}


.imageDetails{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.actionButtons{
    margin-top: 10px;
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
}

.addToCart{
    background: black;
    color: white;
    padding: 10px 10px 10px 10px;
    border-radius: 30px;

    font-weight: bold;
    cursor: pointer;
    transition: background 0.05s ease, color 0.05s ease;
}

.addToCart:active{
    background:white;
    color: black;
    border: 2px solid black;
}
.addToCartQuantity{
    width: 20%;
    text-align: center;
}
.addToCartControllers{
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.addToCartControllers:hover{
    opacity: 0.5;
}

.addToCartControllers:active{
    opacity: 0.2;
}

@media screen and (max-width: 1080px){
    .displayImagesGrid{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 650px){

    .productMaster{
        margin-left:10px;
        margin-right:10px;
    }

    .displayImagesGrid{
        grid-template-columns: 1fr;
    }
}